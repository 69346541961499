<template>
  <div class="trfCommonSearch">
    <div class="filter">
      <span class="screen ">筛选：</span>
      <!--城市选择-->
      <el-popover
        placement="right-start"
        width="280"
        trigger="click"
        v-model="popoverShow"
      >
        <div class="cityDiv">
          <searchCity
            @cityChange="cityListClick"
            ref="searchCity"
            :countryId="country_id"
          ></searchCity>
        </div>
        <el-button class=" screenBtn" slot="reference" size="small">
          城市
        </el-button>
      </el-popover>
      <!--单位类型选择-->
      <el-popover
        placement="right-start"
        width="280"
        trigger="click"
        v-model="typeShow"
      >
        <div class="cityDiv" v-if="typeShow">
          <div class="bussinessContent">
            <el-radio-group v-model="unitType" size="mini">
              <el-radio-button
                v-for="item in unitTypeOption"
                :label="item.value"
                :key="item.value"
                size="small"
                >{{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="clearfix">
            <el-button
              type="primary"
              size="small"
              class="fr"
              @click="saveUnitType"
              >确认
            </el-button>
            <el-button
              type="text"
              size="small"
              class="fr mr15"
              @click="typeShow = false"
              >取消
            </el-button>
          </div>
        </div>
        <el-button class=" screenBtn" slot="reference" size="small">
          单位类型
        </el-button>
      </el-popover>
      <!--船公司-->
      <el-popover
        @show="showCompaine"
        placement="right-start"
        width="600"
        trigger="click"
        v-model="compaineCheck"
      >
        <div>
          <ShippingCompaines
            v-if="compaineCheck"
            class="businessBox"
            :isSelect="filters.checkCompaines"
            :number="5"
            @cancelCompaines="compaineCheck = false"
            @saveCompaines="saveCompaines"
          ></ShippingCompaines>
        </div>
        <el-button class="fl screenBtn" slot="reference" size="small">
          船公司
        </el-button>
      </el-popover>
      <!--优势航线-->
      <el-popover
        placement="right-start"
        width="600"
        trigger="click"
        v-model="majorShow"
        @show="showMajor"
      >
        <majorLine
          v-if="majorShow"
          :number="5"
          :isSelect="filters.checkLine"
          @cancelLine="majorShow = false"
          @saveLine="saveLine"
        />
        <el-button class="screenBtn" slot="reference" size="small">
          优势航线
        </el-button>
      </el-popover>
      <!--优势业务-->
      <el-popover
        @show="showBusiness"
        placement="right-start"
        width="600"
        trigger="click"
        v-model="bussinessCheck"
      >
        <div>
          <businessClass
            v-if="bussinessCheck"
            class="businessBox"
            :isSelect="checkClassData"
            @closeClass="bussinessCheck = false"
            @saveClass="savaClass"
            :number="5"
          ></businessClass>
        </div>
        <el-button class="fl screenBtn" slot="reference" size="small">
          优势业务
        </el-button>
      </el-popover>
      <!--年份选择-->
      <el-popover
        placement="right-start"
        width="400"
        trigger="click"
        v-model="yearsCheck"
        @show="yearsShow"
      >
        <yearSearch :isSelect="filters.checkYear"  @closeYear="yearsCheck = false" @saveYear="saveYear" />
        <el-button class="screenBtn" slot="reference" size="small">
          成立时间
        </el-button>
      </el-popover>
    </div>
    <div class="haveSelect" v-if="filters.city||filters.unitType||filters.checkCompaines.length||filters.checkLine.length||filters.checkClass.length||filters.checkYear">
      <p class="lefttext">{{ $t("companyhaveSeletc") }}：</p>
      <div class="tagswrap">
        <!--城市-->
        <el-tag v-if="filters.city" class="city" closable @close="closeCity"
          >{{ filters.city.city_name_en|priorFormat(filters.city.city_name_zh,LOCALE) }}
        </el-tag>
        <!--单位类型-->
        <el-tag v-if="filters.unitType" class="unittype" closable @close="closeunitType"
        >{{ filters.unitType|unitTypeFormat(LOCALE)}}
        </el-tag>
        <!--船公司-->
        <el-tag
            class="ferry"
            v-for="(tag, index) in filters.checkCompaines"
            :key="index"
            closable
            @close="closeCompany(index)"
        >{{ tag.name|textFormat }}</el-tag
        >
        <!-- 优势航线 -->
        <el-tag
            class="route"
            v-for="(tag, index) in filters.checkLine"
            :key="index"
            closable
            @close="closeRoute(index)"
        >{{ tag|shippingFormat(LOCALE) }}</el-tag
        >
        <!-- 优势业务 -->
        <el-tag
            class="business"
            v-for="(tag, index) in filters.checkClass"
            :key="index"
            closable
            @close="closeClass(index)"
        >{{ tag|bussinessFormat(LOCALE) }}</el-tag
        >
         <!--成立时间-->
        <el-tag v-if="filters.checkYear" class="year" closable @close="closeYear"
          >{{ filters.checkYear|trfYearFormat(LOCALE) }}
        </el-tag>
      </div>
    </div>
  </div>
</template>

<script>
import { getCityListByCountryId } from "~/baseUtils/AddressConfig";
import searchCity from "~dls/components/common/searchCity";
import yearSearch from "~dls/components/company/unit/yearSearch";
import majorLine from "~dls/components/company/unit/majorLine";
import businessClass from "~dls/components/company/unit/businessClass";
import ShippingCompaines from "~dls/components/company/unit/ShippingCompaines";

import {getBusinessObject} from '~/basePlugins/filters'

export default {
  name: "filterSearch",
  components: {
    searchCity,
    yearSearch,
    majorLine,
    businessClass,
    ShippingCompaines,
  },
  props: {},
  data() {
    return {
      popoverShow: false,
      country_id: 1,
      yearsCheck: false,
      majorShow: false,
      haveCheckMajor: [],
      bussinessCheck: false,
      business_classification: {
        Convertional: [],
        "Dangerous Cargo": [],
        "Other convertional": [],
        "Particular Cargo": [],
        Services: [],
      },
      compaineCheck: false,
      typeShow: false,
      unitType: [],
      unitTypeOption: [
        {
          value: 2,
          label: "理事单位",
        },
        {
          value: 1,
          label: "会员单位",
        },
      ],
      filters: {
        city: null,
        unitType: null,
        checkCompaines: [],
        checkLine: [],
        checkClass: [],
        checkYear: null,
      },
      checkClassData:{}
    };
  },
  mounted() {},
  methods: {
    cityListClick(item) {
      console.log(item)
      this.filters.city = item
      this.popoverShow = false;
      this.handleSearch()
    },
    yearsShow() {},
    saveUnitType() {
      this.filters.unitType = this.unitType;
      console.log(this.filters.unitType)
      this.typeShow = false;
      this.handleSearch()
    },
    saveYear(value) {
      console.log(value)
      this.filters.checkYear = value;
      this.yearsCheck = false;
      this.handleSearch()
    },
    showMajor() {},
    saveLine(value) {
      console.log(value);
      this.filters.checkLine = value;
      this.majorShow = false;
      this.handleSearch()
    },
    savaClass(value) {
      console.log(value)
      this.filters.checkClass = value;
      this.bussinessCheck = false;
      this.handleSearch()
    },
    showBusiness() {
      console.log(this.filters.checkClass)
      this.checkClassData=this.filters.checkClass.join(',')
      this.checkClassData=getBusinessObject(this.checkClassData)
    },
    showCompaine() {},
    saveCompaines(value) {
      console.log(value);
      this.filters.checkCompaines = value;
      this.compaineCheck = false;
      this.handleSearch()
    },
    handleSearch() {
      this.$emit("handleSearch", this.filters);
    },
    closeCity(){
      this.filters.city=null
      this.handleSearch()
    },
    closeunitType(){
      this.unitType=null
      this.filters.unitType=null
      this.handleSearch()
    },
    closeCompany(index){
      this.filters.checkCompaines.splice(index,1)
      this.handleSearch()
    },
    closeRoute(index){
      this.filters.checkLine.splice(index,1)
      this.handleSearch()
    },
    closeClass(index){
      this.filters.checkClass.splice(index,1)
      this.handleSearch()
    },
    closeYear(){
      this.filters.checkYear=null
      this.handleSearch()
    },
  },
};
</script>

<style scoped lang="less">
.trfCommonSearch {
  background: #fff;
  padding: 14px 30px;

  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .screenBtn {
      margin-right: 15px;
    }
  }
}

.mr15 {
  margin-right: 15px;
}

.haveSelect {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .lefttext {
    width: 80px;
    position: relative;
    top: -4px;
  }

  .tagswrap {
    flex: 1;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    .city {
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: rgba(240, 250, 255, 0.9);
      border: 1px solid rgba(145, 213, 255, 1);
      color: #91d5ff;

      /deep/ .el-tag__close {
        color: #91d5ff;
      }

      /deep/ .el-tag__close:hover {
        color: #fff;
        background-color: #1890ff;
      }
    }

    .unittype {
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: rgba(237, 243, 254, 1);
      border: 1px solid rgba(189, 210, 253, 1);
      color: #2c6df2;

      /deep/ .el-tag__close {
        color: #2c6df2;
      }

      /deep/ .el-tag__close:hover {
        color: #fff;
        background-color: #1890ff;
      }
    }

    .ferry {
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: #f3fffa;
      border: 1px solid rgba(189, 239, 219, 1);
      color: #0dad6d;

      /deep/ .el-tag__close {
        color: #0dad6d;
      }

      /deep/ .el-tag__close:hover {
        color: #fff;
        background-color: #0dad6d;
      }
    }

    .route {
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: #f7f7f7;
      border: 1px solid #d9d9d9;
      color: #666666;

      /deep/ .el-tag__close {
        color: #666666;
      }

      /deep/ .el-tag__close:hover {
        color: #fff;
        background-color: #666666;
      }
    }

    .business {
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: #fffae9;
      border: 1px solid #fbe5a2;
      color: #f7c52b;

      /deep/ .el-tag__close {
        color: #f7c52b;
      }

      /deep/ .el-tag__close:hover {
        color: #fff;
        background-color: #f7c52b;
      }
    }

    .year {
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: #eeffff;
      border: 1px solid #aad8d8;
      color: #5c9d9d;

      /deep/ .el-tag__close {
        color: #5c9d9d;
      }

      /deep/ .el-tag__close:hover {
        color: #fff;
        background-color: #5c9d9d;
      }
    }

    //.found_year {
    //  background-color: #fff5ed;
    //  border: 1px solid #ffd8b8;
    //  color: #ff943d;
    //
    //  /deep/ .el-tag__close {
    //    color: #ff943d;
    //  }
    //
    //  /deep/ .el-tag__close:hover {
    //    color: #fff;
    //    background-color: #ff943d;
    //  }
    //}
  }
}
</style>
